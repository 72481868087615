<template>
  <div class="box">
    <div class="item">
      <div class="label"><span>姓名</span>:</div>
      <input type="text" placeholder="请输入您的姓名" v-model="data.username" />
    </div>

    <div class="item">
      <div class="label"><span>单位</span>:</div>
      <input type="text" placeholder="请输入您的单位名称" v-model="data.company" />
    </div>

    <div class="item">
      <div class="label"><span>职务</span>:</div>
      <input type="text" placeholder="请输入您的职位" v-model="data.position" />
    </div>

    <div class="item">
      <div class="label"><span>手机</span>:</div>
      <input type="text" placeholder="请输入您的手机号" v-model="data.mobile_phone" />
    </div>

    <div class="item">
      <div class="label"><span>意向课程</span>:</div>
      <input type="text" placeholder="请输入您想报名的课程名称" v-model="data.intended_course" />
    </div>

    <div class="buts">
      <button @click="handleSubmit">提&nbsp;&nbsp;交</button>
      <button @click="handlerClear">取&nbsp;&nbsp;消</button>
    </div>
  </div>

  <el-dialog v-model="dialogVisible" title="提示" :width="isCalculation ? '70%' : '30%'">
    <span class="msg" :style="{ color: data.isOk ? '#67C23A' : '#F56C6C' }">{{ data.msg }}</span>
    <template #footer>
      <el-button color="#626aef" type="primary" @click="dialogVisible = false">确认</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import { apiSignup } from '@/api/layout.js'
import { reactive, ref,computed } from 'vue'
import { ElNotification } from 'element-plus'
let data = reactive({
  username: '', // 姓名
  company: '', // 单位
  position: '', // 职位
  mobile_phone: '', // 手机号
  intended_course: '', // 意向课程
  msg: '', //提示的消息
  isOk: true,
  isSubmit: false
})

const isMobile = function () {
  return 
}

let dialogVisible = ref(false)
let timer = null
const handleSubmit = () => {
  if (timer) clearTimeout(timer)
  timer = setTimeout(async () => {
    let reslut = await apiSignup({
      username: data.username, // 姓名
      company: data.company, // 单位
      position: data.position, // 职位
      mobile_phone: data.mobile_phone, // 手机号
      intended_course: data.intended_course, // 意向课程
      is_en:3
    })
    if (reslut.code == 200) {
      // ElNotification({
      //   title: '提示',
      //   message: reslut.msg,
      //   type: 'success'
      // })
      data.msg = reslut.msg
      data.isOk = true
    } else {
      // ElNotification({
      //   title: '提示',
      //   message: reslut.msg,
      //   type: 'error'
      // })
      data.msg = reslut.msg
      data.isOk = false
    }
    dialogVisible.value = true
    clearTimeout(timer)
  }, 300)
}
const handlerClear = () => {
  data.username = ''
  data.company = ''
  data.position = ''
  data.mobile_phone = ''
  data.intended_course = ''
}

const isCalculation = computed(() => window.innerWidth < 1200)
</script>

<style lang="scss" scoped>
@media screen and (min-width: $media) { 
.box {
  width: 100%;
  height: 5.0781rem;
  background: #e8e8e8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  >.item {
    display: flex;
    align-items: center;
    padding-bottom: .25rem;

    .label {
      display: flex;
      width: 1.25rem;
      height: .3125rem;
      font-size: .2344rem;
      font-weight: 500;
      color: #a6291a;
      line-height: .25rem;
      padding-right: .2344rem;

      >span {
        flex: 1;
        text-align-last: justify;
      }
    }

    >input {
      width: 5.0781rem;
      height: .4375rem;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: .0078rem solid #de3a00;
      padding: .0938rem 0px .0938rem .1719rem;
      font-size: .1719rem;

      &::placeholder {
        font-size: .1719rem;
        color: #cbcbcb;
        letter-spacing: .0547rem;
        line-height: .3438rem;
      }
    }
  }

  .buts {
    margin-top: .2344rem;
    width: 50%;
    height: .4688rem !important;
    display: flex;
    justify-content: space-evenly;

    >button {
      width: 1.4063rem;
      height: .4688rem;
      cursor: pointer;
      border-radius: .0234rem;
      font-size: .1406rem;
    }

    & button:first-child {
      background-color: #de3a00;
      color: #fff;
      border: none;
    }

    & button:last-child {
      background-color: #fff;
      color: #000;
      border: .0078rem solid #de3a00;
    }
  }
}
.el-dialog__footer {

  .ivu-btn {
    background-color: #a6291a;

    :deep(span) {
      color: #fff !important;
    }
  }
}

.msg {
  font-size: .1563rem;
}
}

@media screen and (max-width: $media) { 
  .box {
  width: 100%;
  background: #e8e8e8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: .4rem 0px;
  margin-bottom: .6rem;

  >.item {
    display: flex;
    align-items: center;
    padding-bottom: .25rem;

    .label {
      width: 3rem;
      display: flex;
      height: .3125rem;
      font-size: .6rem;
      font-weight: 500;
      color: #a6291a;
      line-height: .25rem;
      padding-right: .2344rem;

      >span {
        flex: 1;
        text-align-last: justify;
      }
    }

    >input {
      width: 10rem;
      height: 1.2rem;
      background: #ffffff;
      border-radius: .16rem;
      opacity: 1;
      border: .0078rem solid #de3a00;
      padding: 0px .4rem;
      font-size: .6rem;

      &::placeholder {
        font-size: .6rem;
        color: #cbcbcb;
        letter-spacing: .06rem;
        line-height: .6rem;
      }
    }
  }

  .buts {
    margin-top: .4rem;
    margin-left: 2rem;
    width: 70%;
    height: 1.6rem;
    display: flex;
    justify-content: space-evenly;

    >button {
      width: 3.6rem;
      height: 1.2rem;
      cursor: pointer;
      border-radius: .0234rem;
      font-size: .5rem;
    }

    & button:first-child {
      background-color: #de3a00;
      color: #fff;
      border: none;
    }

    & button:last-child {
      background-color: #fff;
      color: #000;
      border: .0078rem solid #de3a00;
    }
  }
}
.el-dialog__footer {

  .ivu-btn {
    background-color: #a6291a;

    :deep(span) {
      color: #fff !important;
    }
  }
}

.msg {
  font-size: .6rem;
}
}

</style>
